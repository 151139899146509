exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-apps-apps-home-js": () => import("./../../../src/pages/apps/apps-home.js" /* webpackChunkName: "component---src-pages-apps-apps-home-js" */),
  "component---src-pages-apps-js": () => import("./../../../src/pages/apps.js" /* webpackChunkName: "component---src-pages-apps-js" */),
  "component---src-pages-designs-js": () => import("./../../../src/pages/designs.js" /* webpackChunkName: "component---src-pages-designs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-apps-card-js": () => import("./../../../src/pages/landing/apps-card.js" /* webpackChunkName: "component---src-pages-landing-apps-card-js" */),
  "component---src-pages-landing-hero-js": () => import("./../../../src/pages/landing/hero.js" /* webpackChunkName: "component---src-pages-landing-hero-js" */),
  "component---src-pages-landing-landing-apps-js": () => import("./../../../src/pages/landing/landing-apps.js" /* webpackChunkName: "component---src-pages-landing-landing-apps-js" */),
  "component---src-pages-landing-landing-designs-js": () => import("./../../../src/pages/landing/landing-designs.js" /* webpackChunkName: "component---src-pages-landing-landing-designs-js" */),
  "component---src-pages-landing-landing-js": () => import("./../../../src/pages/landing/landing.js" /* webpackChunkName: "component---src-pages-landing-landing-js" */),
  "component---src-pages-landing-landing-projects-js": () => import("./../../../src/pages/landing/landing-projects.js" /* webpackChunkName: "component---src-pages-landing-landing-projects-js" */),
  "component---src-pages-my-journey-js": () => import("./../../../src/pages/my-journey.js" /* webpackChunkName: "component---src-pages-my-journey-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-templates-blog-post-archive-js": () => import("./../../../src/templates/blog-post-archive.js" /* webpackChunkName: "component---src-templates-blog-post-archive-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

